import React, { useState } from "react";
import { Link } from "gatsby";
import Obfuscate from "react-obfuscate";
import { Icon } from "../../common/Icon/Icon";
import classNames from "classnames";
import styles from "./Header.module.scss";

const Header = ({ path }) => {
  const [menuActive, toggleMenuActive] = useState(false);
  return (
    <header className={styles.header}>
      <div className={styles.logo}>
        <Link activeClassName={styles.active} to="/">
          <Icon name="logo" size="custom" />
          <span className="visually-hidden">No More Heroes</span>
        </Link>
      </div>
      <div className={styles.hamburgerContainer}>
        <button
          className={styles.hamburger}
          aria-label="menu"
          onClick={() => toggleMenuActive(!menuActive)}
        >
          {menuActive ? (
            <Icon name="hamburger-close" className={styles.hamburgerIcon} />
          ) : (
            <Icon name="hamburger" className={styles.hamburgerIcon} />
          )}
        </button>
      </div>
      <ul className={classNames(styles.menu, { [styles.active]: menuActive })}>
        <li>
          <Link activeClassName={styles.active} to="/works">
            Work
          </Link>
        </li>
        <li>
          <Link activeClassName={styles.active} to="/about">
            About us
          </Link>
        </li>
        <li>
          <Link activeClassName={styles.active} to="/contact">
            Contact
          </Link>
        </li>
      </ul>
    </header>
  );
};

export { Header };
