import React from "react";

import classNames from "classnames";
import { ReactComponent as Twitter } from "./assets/twitter.svg";
import { ReactComponent as Instagram } from "./assets/instagram.svg";
import { ReactComponent as LogoIcon } from "./assets/logo.svg";
import { ReactComponent as HamburgerIcon } from "./assets/hamburger.svg";
import { ReactComponent as HamburgerCloseIcon } from "./assets/hamburger-close.svg";
import { ReactComponent as ChevronDownIcon } from "./assets/chevron-down.svg";
import { ReactComponent as ChevronRightIcon } from "./assets/chevron-right.svg";
import { ReactComponent as BuiltByMTJ } from "./assets/made-by-mtj.svg";

import styles from "./Icon.module.scss";

const IconKeys = {
  twitter: Twitter,
  instagram: Instagram,
  mtj: BuiltByMTJ,
  hamburger: HamburgerIcon,
  "hamburger-close": HamburgerCloseIcon,
  logo: LogoIcon,
  "chevron-down": ChevronDownIcon,
  "chevron-right": ChevronRightIcon
};

const Icon = ({ name, size = "medium", className }) => {
  const cls = classNames(styles.icon, styles[size], className);
  const El = IconKeys[name];

  if (!El) {
    return null;
  }

  return (
    <span className={cls}>
      <El />
    </span>
  );
};

export { Icon };
