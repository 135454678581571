import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Obfuscate from "react-obfuscate";
import { Icon } from "../../common/Icon/Icon";

import styles from "./Footer.module.scss";

const Footer = ({ path }) => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      sanitySiteSettings {
        email
        twitter
        instagram
      }
    }
  `);

  return (
    <footer className={styles.footer}>
      <div className={styles.footerInner}>
        <div className={styles.logo}>&#169; {new Date().getFullYear()} No More Heroes</div>
        <Obfuscate email={data.sanitySiteSettings.email} target="_blank" />
      </div>
      <div className={`${styles.footerInner} ${styles.secondLine}`}>
        <div className={styles.logo}>
          <Link to="https://www.mtj.io/" target="_blank">
            <span className={styles.madeBy}>Made by </span>
            <Icon name="mtj" size="custom" />
            <span className="visually-hidden">made by MTJ</span>
          </Link>
        </div>
        <div className={styles.social}>
          {data.sanitySiteSettings.twitter && (
            <Link to={data.sanitySiteSettings.twitter} target="_blank" className={styles.twitter}>
              <Icon name="twitter" size="custom" />
              <span className="visually-hidden">Twitter</span>
            </Link>
          )}
          {data.sanitySiteSettings.instagram && (
            <Link
              to={data.sanitySiteSettings.instagram}
              target="_blank"
              className={styles.instagram}
            >
              <Icon name="instagram" size="custom" />
              <span className="visually-hidden">Instagram</span>
            </Link>
          )}
        </div>
      </div>
    </footer>
  );
};

export { Footer };
