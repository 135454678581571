import React from "react";
import Helmet from "react-helmet";
import "../../../styles/custom-properties.css";
import "../../../styles/layout.scss";
import styles from "./Wrapper.module.scss";
import { Header } from "../Header/Header";
import { Footer } from "../Footer/Footer";
// import { Footer } from "../Footer/Footer";

const Wrapper = ({ children, siteTitle, location }) => {
  const bodyClass = location?.pathname.replace(/^\/([^\/]*).*$/, "$1")
    ? location?.pathname.replace(/^\/([^\/]*).*$/, "$1")
    : "home";
  return (
    <>
      <Helmet
        bodyAttributes={{
          class: bodyClass
        }}
      />
      <div className={styles.wrapper}>
        <Header path={location ? location.pathname : "/unknown"} />
        <main className={styles.content}>{children}</main>
        <Footer path={location ? location.pathname : "/unknown"} />
      </div>
    </>
  );
};

export { Wrapper };
